/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 64.2.1-v202404170327
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqAddOnsApiClass {
 
   constructor() {}

  /**
   * @summary Archive an Add-on item
   * @param {string} id - ID of the Add-on item to archive.
   */
  public archiveAddOn(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/add-ons/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Create a new Add-on item
   */
  public createAddOn(
    body: models.AddOnInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/add-ons`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AddOnOutputV1>;
  }

  /**
   * @summary Get an Add-on item
   * @param {string} id - The Seeq ID for the Add-on
   */
  public getAddOn(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/add-ons/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AddOnOutputV1>;
  }

  /**
   * @summary Get a list of Add-on items
   * @param {number} [offset=0] - The pagination offset, the index of the first Add-on item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of Add-on items that will be returned in this page of results
   */
  public getAddOns(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/add-ons`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AddOnOutputListV1>;
  }

  /**
   * @summary Get a list of all installed Add-ons. If the acting user is a non-administrator, the in-development Add-ons of other users will not be listed.
   * @param {number} [offset=0] - The pagination offset, the index of the first Add-on item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of Add-on items that will be returned in this page of results
   */
  public getAllAddOns(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/add-ons/all`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AddOnPreviewOutputListV1>;
  }

  /**
   * @summary Update an Add-on
   * @param {string} id - The Seeq ID for the Add-on
   */
  public updateAddOn(
    body: models.AddOnInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/add-ons/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AddOnOutputV1>;
  }

}


export const sqAddOnsApi = new sqAddOnsApiClass();
